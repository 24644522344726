<template>
  <div class="imgstype">
    <div v-if="imgs[0].videoUrl == null">
      <!-- 1 -->
      <div v-if="imgs.length == 1">
        <van-image
          fit="cover"
          :src="imgs[0].photoThumbnailUrl"
          class=""
          style="width: 100%"
          @click="preimg(0)"
        ></van-image>
      </div>
      <!-- 2 -->
      <div
        v-if="imgs.length == 2"
        class="flex_start flex_items"
        style="overflow: hidden"
      >
        <van-image
          v-for="(item, index) in imgs"
          :key="index"
          fit="cover"
          :src="item.photoThumbnailUrl"
          class=""
          @click="preimg(index)"
          style="width: 50vw; height: 50vw; margin-right: 2px"
        ></van-image>
      </div>
      <!-- 3 -->
      <div
        v-if="imgs.length == 3"
        class="flex_start flex_items"
        style="overflow: hidden"
      >
        <van-image
          fit="cover"
          :src="imgs[0].photoThumbnailUrl"
          class=""
          @click="preimg(0)"
          style="width: 60vw; height: 80.3vw; margin-right: 2px"
        ></van-image>
        <div class="flex_column flex_itmes">
          <van-image
            fit="cover"
            :src="imgs[1].photoThumbnailUrl"
            class=""
            style="width: 40vw; height: 40vw"
            @click="preimg(1)"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[2].photoThumbnailUrl"
            class=""
            style="width: 40vw; height: 40vw; margin-top: 2px"
            @click="preimg(2)"
          ></van-image>
        </div>
      </div>
      <!-- 4 -->
      <div
        v-if="imgs.length == 4"
        class="flex_column flex_items"
        style="overflow: hidden"
      >
        <div class="flex_start flex_items" style="width: 100%">
          <van-image
            fit="cover"
            :src="imgs[0].photoThumbnailUrl"
            @click="preimg(0)"
            class=""
            style="width: 50vw; height: 50vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[1].photoThumbnailUrl"
            class=""
            style="width: 50vw; height: 50vw"
            @click="preimg(1)"
          ></van-image>
        </div>
        <div class="flex_start flex_items" style="margin-top: 2px; width: 100%">
          <van-image
            fit="cover"
            :src="imgs[2].photoThumbnailUrl"
            class=""
            @click="preimg(2)"
            style="width: 50vw; height: 50vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[3].photoThumbnailUrl"
            @click="preimg(3)"
            class=""
            style="width: 50vw; height: 50vw"
          ></van-image>
        </div>
      </div>
      <!-- 5 -->
      <div
        v-if="imgs.length == 5"
        class="flex_column flex_items"
        style="overflow: hidden"
      >
        <div class="flex_start flex_items">
          <van-image
            fit="cover"
            :src="imgs[0].photoThumbnailUrl"
            @click="preimg(0)"
            class=""
            style="width: 50vw; height: 50vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[1].photoThumbnailUrl"
            @click="preimg(1)"
            class=""
            style="width: 50vw; height: 50vw; margin-right: 2px"
          ></van-image>
        </div>
        <div class="flex_start flex_items" style="margin-top: 2px">
          <van-image
            fit="cover"
            :src="imgs[2].photoThumbnailUrl"
            @click="preimg(2)"
            class=""
            style="width: 33.33vw; height: 33.33vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[3].photoThumbnailUrl"
            @click="preimg(3)"
            class=""
            style="width: 33.33vw; height: 33.33vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[4].photoThumbnailUrl"
            @click="preimg(4)"
            class=""
            style="width: 33.33vw; height: 33.33vw; margin-right: 2px"
          ></van-image>
        </div>
      </div>
      <!-- 6 -->
      <div
        v-if="imgs.length == 6"
        class="flex_column flex_items"
        style="overflow: hidden"
      >
        <div class="flex_start flex_items">
          <van-image
            fit="cover"
            :src="imgs[0].photoThumbnailUrl"
            @click="preimg(0)"
            class=""
            style="width: 66.66vw; height: 66.96vw; margin-right: 2px"
          ></van-image>
          <div class="flex_column flex_itmes">
            <van-image
              fit="cover"
              :src="imgs[1].photoThumbnailUrl"
              @click="preimg(1)"
              class=""
              style="width: 33.33vw; height: 33.33vw"
            ></van-image>
            <van-image
              fit="cover"
              :src="imgs[2].photoThumbnailUrl"
              @click="preimg(2)"
              class=""
              style="width: 33.33vw; height: 33.33vw; margin-top: 2px"
            ></van-image>
          </div>
        </div>
        <div class="flex_start flex_items" style="margin-top: 2px">
          <van-image
            fit="cover"
            :src="imgs[3].photoThumbnailUrl"
            @click="preimg(3)"
            class=""
            style="width: 33.33vw; height: 33.33vw"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[4].photoThumbnailUrl"
            @click="preimg(4)"
            class=""
            style="width: 33.33vw; height: 33.33vw"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[5].photoThumbnailUrl"
            @click="preimg(5)"
            class=""
            style="width: 33.33vw; height: 33.33vw"
          ></van-image>
        </div>
      </div>
      <!-- 7 -->
      <div
        v-if="imgs.length == 7"
        class="flex_column flex_items"
        style="overflow: hidden"
      >
        <div class="flex_start flex_items" style="width: 100%">
          <van-image
            fit="cover"
            :src="imgs[0].photoThumbnailUrl"
            @click="preimg(0)"
            class=""
            style="width: 50vw; height: 40vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[1].photoThumbnailUrl"
            @click="preimg(1)"
            class=""
            style="width: 50vw; height: 40vw"
          ></van-image>
        </div>
        <div class="flex_start flex_items" style="width: 100%">
          <van-image
            fit="cover"
            :src="imgs[2].photoThumbnailUrl"
            @click="preimg(2)"
            class=""
            style="width: 50vw; height: 40vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[3].photoThumbnailUrl"
            @click="preimg(3)"
            class=""
            style="width: 50vw; height: 40vw"
          ></van-image>
        </div>
        <div class="flex_start flex_items" style="margin-top: 2px">
          <van-image
            fit="cover"
            :src="imgs[4].photoThumbnailUrl"
            @click="preimg(4)"
            class=""
            style="width: 33.33vw; height: 33.33vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[5].photoThumbnailUrl"
            @click="preimg(5)"
            class=""
            style="width: 33.33vw; height: 33.33vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[6].photoThumbnailUrl"
            @click="preimg(6)"
            class=""
            style="width: 33.33vw; height: 33.33vw"
          ></van-image>
        </div>
      </div>
      <!-- 8 -->
      <div
        v-if="imgs.length == 8"
        class="flex_column flex_items"
        style="overflow: hidden"
      >
        <div class="flex_start flex_items" style="width: 100%">
          <van-image
            fit="cover"
            :src="imgs[0].photoThumbnailUrl"
            @click="preimg(0)"
            class=""
            style="width: 50vw; height: 40vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[1].photoThumbnailUrl"
            @click="preimg(1)"
            class=""
            style="width: 50vw; height: 40vw"
          ></van-image>
        </div>
        <div class="flex_start flex_items" style="margin-top: 2px">
          <van-image
            fit="cover"
            :src="imgs[2].photoThumbnailUrl"
            @click="preimg(2)"
            class=""
            style="width: 33.33vw; height: 33.33vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[3].photoThumbnailUrl"
            @click="preimg(3)"
            class=""
            style="width: 33.33vw; height: 33.33vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[4].photoThumbnailUrl"
            @click="preimg(4)"
            class=""
            style="width: 33.33vw; height: 33.33vw"
          ></van-image>
        </div>
        <div class="flex_start flex_items" style="margin-top: 2px">
          <van-image
            fit="cover"
            :src="imgs[5].photoThumbnailUrl"
            @click="preimg(5)"
            class=""
            style="width: 33.33vw; height: 33.33vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[6].photoThumbnailUrl"
            @click="preimg(6)"
            class=""
            style="width: 33.33vw; height: 33.33vw; margin-right: 2px"
          ></van-image>
          <van-image
            fit="cover"
            :src="imgs[7].photoThumbnailUrl"
            @click="preimg(7)"
            class=""
            style="width: 33.33vw; height: 33.33vw"
          ></van-image>
        </div>
      </div>
      <!-- 9 -->
      <div
        v-if="imgs.length == 9"
        class="flex_warp flex_items"
        style="overflow: hidden"
      >
        <van-image
          v-for="item in imgs"
          :key="item.id"
          fit="cover"
          :src="item.photoThumbnailUrl"
          @click="preimg(index)"
          class=""
          style="
            width: 32.6vw;
            height: 32.6vw;
            margin-right: 2px;
            margin-top: 2px;
          "
        ></van-image>
      </div>
    </div>
    <div v-else style="width: 100%; text-align: center" ref="pronbit">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      >
      </video-player>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      playerOptions: {},
      isplay: false,
    };
  },
  props: ["imgs", "id"],
  mounted() {
    if (this.imgs[0].videoUrl != null) {
      setTimeout(() => {
        window.addEventListener("scroll", this.handleScrollx, true);
      }, 20);

      this.playerOptions = {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: true,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "en",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: this.imgs[0].videoUrl,
          },
        ],
        //你的封面地址
        poster: "",
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        // notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
        },
      };
    }
  },
  methods: {
    handleScrollx() {
      setTimeout(() => {
        if (
          this.$refs.pronbit.getBoundingClientRect().top < 330 &&
          this.$refs.pronbit.getBoundingClientRect().top > 20
        ) {
          this.playerOptions.autoplay = true;
        } else {
          this.playerOptions.autoplay = false;
        }
      }, 20);
    },
    preimg(index) {
      let arr = [];
      this.imgs.forEach((item) => {
        arr.push(item.photoThumbnailUrl);
      });
      this.util.preimg(index, arr);
    },
  },
};
</script>