<template>
  <div class="homeheader">
    <div class="p15 hometop">
      <div class="flex_between flex_items">
        <van-image
          width="33"
          fit="contain"
          class="mr10"
          :src="require('@/images/home/pixleft.png')"
          @click="app"
        ></van-image>
        <div
          v-if="istoken"
          class="f17 bold flex_items flex_center pixbg plr10 ptb5"
          style="color: #ff5500"
        >
          <span>{{ info.balance }}&nbsp;PIX</span>
        </div>
        <div class="flexX_column" @click="energy">
          <van-image
            class=""
            fit="contain"
            width="50"
            :src="require('@/images/home/energy.png')"
          ></van-image>
          <p v-if="istoken" class="f-center" style="font-size: 11px">
            <span style="color: #ff5500">{{ info.power }}&nbsp;</span>/&nbsp;{{
              info.current_max_energy
            }}
          </p>
          <p v-else class="f-center" style="font-size: 11px">
            <span style="color: #ff5500">-&nbsp;</span>/&nbsp;-
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
      istoken: false,
    };
  },
  activated() {
    if (localStorage.getItem("token")) {
      this.istoken = true;
      this.getData();
    } else {
      this.istoken = false;
    }
  },
  methods: {
    getData() {
      this.util
        .energy(
          this.HOST + "/account/user/" + localStorage.getItem("account"),
          {
            appVersion: "1.0.6",
            country: "cn",
            deviceName: "iPhone1",
            language: "en",
            osVersion: "14.7.1",
            platform: 2,
            timestamp: Date.parse(new Date()),
            timezone: "Asia/Shanghai",
          }
        )
        .then((res) => {
          console.log(res);
          res.data.accountInfo.current_max_energy =
            parseInt(res.data.accountInfo.current_max_energy) / 1000000000;
          this.info = res.data.accountInfo;
        });
    },
    app() {
      this.util.app();
    },
    energy() {
      if (localStorage.getItem("token")) {
        window.location.href =
          "http://pixie.mobi/en/energy/?Authorization=" +
          localStorage.getItem("token").replace("bearer ", "");
      }
    },
  },
};
</script>
<style>
.homeheader .hometop {
  background: url("../images/home/headerbg.png") no-repeat;
  height: 100%;
  background-size: 100% 100%;
}
.homeheader .pixbg {
  background: url("../images/home/pixbg.png") no-repeat;
  height: 100%;
  background-size: 100% 100%;
  min-width: 60px;
}
</style>