const imgUrl = "https://imgaes.yiniaoweb.com/gw/";
const tuhose = '@/images'

import axios from "axios";
import $qs from "qs";
import router from "../router/index.js";
import store from '../store/index.js'
import { Toast } from "vant";
import { ImagePreview } from 'vant';
import md5 from 'js-md5';

function conver(s) {
    return s < 10 ? '0' + s : s;
}
export default {
    imgUrl,
    tuhose,
    post(url, data = {}) {
        return new Promise((resolve, reject) => {
            let time = Date.parse(new Date())
            axios.post(url, $qs.stringify(data), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': localStorage.getItem("token"),
                    'timestamp': time,
                    // 'Referer': 'test-h5.pixie.mobi',
                    // 'signature': md5(md5(data.toString()) + '&token=' + localStorage.getItem("token").replace('bearer ', "") + '&timestamp=' + time + '&secret=b8c3b4ce6dce2513c1c48e2708c66f52')
                }
            }).then(
                response => {
                    if (response.data.code == '403' || response.data.code == '420') {
                        Toast(response.data.msg)
                        localStorage.clear();
                        router.replace({ name: "login" });
                    } else {
                        resolve(response.data);
                    }
                },
                err => {
                    reject(err);
                }
            );
        });
    },
    //修改信息
    mpp(url, data) {
        return new Promise(function(resolve, reject) {
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': localStorage.getItem("token")
                }
            }).then(
                response => {
                    console.log(response)
                    if (response.data.code == '403' || response.data.code == '420') {
                        Toast(response.data.msg)
                        localStorage.clear();
                        router.replace({ name: "login" });
                    } else {
                        resolve(response.data)
                    }
                },
                err => {
                    reject(err);
                }
            );
        })
    },
    get(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    params: params,
                    headers: {
                        'timestamp': Date.parse(new Date()),
                        // 'Referer': 'test-h5.pixie.mobi',
                        'Authorization': localStorage.getItem("token"),
                        // 'signature': md5(md5(params.toString()) + '&token=' + localStorage.getItem("token").replace('bearer ', "") + '&timestamp=' + Date.parse(new Date()) + '&secret=b8c3b4ce6dce2513c1c48e2708c66f52')
                    }
                })
                .then(response => {
                    // resolve(response.data);
                    if (response.data.code == '403' || response.data.code == '420') {
                        Toast(response.data.msg)
                        localStorage.clear();
                        router.replace({ name: "login" });
                    } else {
                        resolve(response.data);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    //判断手机 pc
    _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
    },
    // 标准时间转换
    datatime(data = {}) {
        var d = new Date(data);
        var datetime =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1) +
            "-" +
            d.getDate() +
            " " +
            d.getHours() +
            ":" +
            d.getMinutes() +
            ":" +
            d.getSeconds();
        return datetime
    },
    // 转化时间戳
    datatamp(time) {
        let date = new Date(time);
        return date.getTime();
    },
    // 时间戳转化为时间
    timestampToTime(timestamp) {
        var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '';
        var D = date.getDate() + '';
        var h = date.getHours() + '';
        var m = date.getMinutes() + '';
        var s = date.getSeconds();
        return Y + M + D + h + m + s;
    },
    // 获取当前时间月日年时分秒
    getnow() {
        var myDate = new Date();
        //获取当前年
        var year = myDate.getFullYear();
        //获取当前月
        var month = myDate.getMonth() + 1;
        //获取当前日
        var date = myDate.getDate();
        var h = myDate.getHours(); //获取当前小时数(0-23)
        var m = myDate.getMinutes(); //获取当前分钟数(0-59)
        var s = myDate.getSeconds();
        //获取当前时间
        return year + '-' + conver(month) + "-" + conver(date) + " " + conver(h) + ':' + conver(m) + ":" + conver(s);
    },
    // 获取当前时间月日年
    getnow2() {
        var myDate = new Date();
        //获取当前年
        var year = myDate.getFullYear();
        //获取当前月
        var month = myDate.getMonth() + 1;
        //获取当前日
        var date = myDate.getDate();
        var h = myDate.getHours(); //获取当前小时数(0-23)
        var m = myDate.getMinutes(); //获取当前分钟数(0-59)
        var s = myDate.getSeconds();
        //获取当前时间
        return year + '-' + conver(month) + "-" + conver(date)
    },
    // 获取昨天日期 num表示-1代表前一天str表示'-'分隔符
    getDay(num, str) {
        var today = new Date();
        var nowTime = today.getTime();
        var ms = 24 * 3600 * 1000 * num;
        today.setTime(parseInt(nowTime + ms));
        var oYear = today.getFullYear();
        var oMoth = (today.getMonth() + 1).toString();
        if (oMoth.length <= 1) oMoth = '0' + oMoth;
        var oDay = today.getDate().toString();
        if (oDay.length <= 1) oDay = '0' + oDay;
        return oYear + str + oMoth + str + oDay;
    },
    // 转化为国际日期格式
    intertime(time) {
        return new Date(time.replace(/-/g, '/').replace('T', ' ')).toISOString()
    },
    // 比较两日期大小
    compareTime(startTime, endTime) {
        var start_time = startTime.replace(/-|\s|:|\//g, "");
        var end_time = endTime.replace(/-|\s|:|\//g, "");
        if (start_time < end_time) {
            return '小于'
        } else {
            return '大于'
        }
    },
    // 比较时间是否为今天
    compare_time(time) {
        var today_end = this.getendtimes();
        var time_old = time;
        if (today_end == time_old) {
            return 1
        } else {
            return 0
        }
    },
    // 获取当日23：59：59时间
    getendtimes() {
        var time_end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
        var time_format = this.format_date(time_end)
        return time_format;
    },
    // 获取当前时间的  年月日时分秒  的时间格式化 20191220100246
    format_date(now) {
        var year = now.getFullYear(); //年
        var month = now.getMonth() + 1; //月
        var day = now.getDate(); //日
        var hh = now.getHours(); //时
        var mm = now.getMinutes(); //分
        var ss = now.getSeconds(); //秒

        var clock = year + "";
        if (month < 10) {
            clock += "0";
        }
        clock += month + "";

        if (day < 10) {
            clock += "0";
        }
        clock += day + "";

        if (hh < 10) {
            clock += "0";
        }
        clock += hh + "";

        if (mm < 10) {
            clock += '0'
        }
        clock += mm;

        if (ss < 10) {
            clock += '0'
        }
        clock += ss;

        return clock;
    },
    // 判断时间为周几
    weekDay(time) {
        var weekDays = ["0", "1", "2", "3", "4", "5", "6"];
        var myDate = new Date(Date.parse(time));
        return weekDays[myDate.getDay()]
    },
    // 退出
    usergetout(url, data = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, $qs.stringify(data), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'accesstoken': localStorage.getItem("token")
                }
            }).then(
                response => {
                    resolve(response.data);
                    localStorage.clear();
                },
                err => {
                    reject(err);
                }
            );
        });
    },
    // 获取高度
    gaodu() {
        return (document.documentElement.clientHeight || document.body.clientHeight)
    },
    // 宽度
    kuandu() {
        return (document.documentElement.clientWidth || document.body.clientWidth)
    },
    // 取消单选
    cancleradio(kind, e, zhi) {
        let huishu = zhi
        e === huishu ? (huishu = "") : (huishu = e);
        return huishu
    },
    // 点赞
    zan(url, data) {
        return new Promise((resolve, reject) => {
            this.post(url, data).then((response) => {
                if (response.code == '000') {
                    resolve(response.data);
                } else {
                    Toast(response.msg);
                }
            })
        })

    },
    // 关注
    follow(url, data) {
        return new Promise((resolve, reject) => {
            this.post(url, data).then((response) => {
                resolve(response);
            })
        })

    },
    // repost
    repost(url, data) {
        return new Promise((resolve, reject) => {
            this.post(url, data).then((response) => {
                console.log(response)
                if (response.code == '000') {
                    resolve('ok')
                    Toast('Reposted')
                } else {
                    Toast(response.msg);
                }
            })
        })

    },
    // 预览
    preimg(index, arr) {
        ImagePreview({
            images: arr,
            showIndex: true,
            loop: false,
            startPosition: index,
        });
    },
    // 举报
    report(url, data) {
        return new Promise((resolve, reject) => {
            this.post(url, data).then((response) => {
                resolve(response);
            })
        })

    },
    // 更新能量
    energy(url, data) {
        return new Promise((resolve, reject) => {
            this.get(url, data).then((response) => {
                if (response.code == '000') {
                    resolve(response);
                } else {
                    Toast(response.msg);
                }
            })
        })
    },
    app(author, permlink) {
        Toast.loading({
            message: "Loading...",
            forbidClick: true,
        });
        if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
            var loadDateTime = new Date();
            window.location = "pixie://?pixbe=nftDetail&author=" + localStorage.getItem('account') + "&permlink=" + localStorage.getItem('token'); //schemreplaceuniversal link
            window.setTimeout(function() {
                var timeOutDateTime = new Date();
                if (timeOutDateTime - loadDateTime < 5000) {
                    window.location = "https://www.pixie.mobi/"; //ioreplace
                } else {
                    window.close();
                }
            }, 500);
        } else if (navigator.userAgent.match(/android/i)) {
            var state = null;
            try {
                window.location = "pixie://?pixbe=nftDetail&author=" + localStorage.getItem('account') + "&permlink=" + localStorage.getItem('token'); //schemreplaceuniversal link
                window.setTimeout(function() {
                    window.location = "https://www.pixie.mobi/."; //androireplace
                }, 500);
            } catch (e) {}
        }
    }

};