<template>
  <div class="zhuanshow">
    <van-popover
      overlay
      v-model="showpop"
      trigger="click"
      :actions="actions"
      @select="onSelect"
    >
      <template #reference>
        <van-image
          fit="contain"
          :src="require('@/images/home/zhuan.png')"
          width="24"
        ></van-image>
      </template>
    </van-popover>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      showpop: false,
      actions: [],
      transmits: false,
    };
  },
  props: ["isshow", "permlink", "author", "ismoreindex", "transmit"],
  created() {
    this.getData();
  },
  methods: {
    getData(aa) {
      if (!aa) {
        this.showpop = this.isshow;
        this.transmits = this.transmit;
      }
      if (!this.transmits) {
        this.actions = [
          { text: "Repost", icon: require("@/images/home/xiaozhuan.png") },
          { text: "Send To", icon: require("@/images/home/send.png") },
          { text: "Share", icon: require("@/images/home/give.png") },
        ];
      } else {
        this.actions = [
          { text: "Reposted", icon: require("@/images/home/reposted.png") },
          { text: "Send To", icon: require("@/images/home/send.png") },
          { text: "Share", icon: require("@/images/home/give.png") },
        ];
      }
    },
    onSelect(action) {
      if (action.text == "Repost") {
        Toast.loading({
          message: "Loading...",
          forbidClick: true,
        });
        this.util
          .repost(this.HOST + "/transmit", {
            author: this.author,
            appVersion: "1.0.5",
            country: "cn",
            language: "en",
            myAccount: localStorage.getItem("account"),
            osVersion: "14.7.1",
            permlink: this.permlink,
            platform: "2",
            timestamp: Date.parse(new Date()),
            timezone: "Asia/Shanghai",
          })
          .then((res) => {
            if (res == "ok") {
              this.transmits = true;
              this.getData("again");
            }
          });
      }
      if (action.text == "Reposted") {
        Toast("The repost cannot be cancled");
      }
      if (action.text == "Share") {
        this.$emit("shareto", {
          zt: true,
          permlink: this.permlink,
          author: this.author,
        });
      }
      if (action.text == "Send To") {
        this.util.app();
      }
    },
  },
};
</script>
<style>
</style>