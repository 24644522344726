<template>
  <div class="login">
    <div class="p20 flex_column">
      <p class="bold f20 mt30">Log into Account</p>
      <div class="mt20">
        <div class="bbe9s flex_start flex_items f18">
          <div
            :style="{
              width: '50%',
              color: active == 0 ? '#000' : '#C7C7C7',
              'border-bottom':
                active == 0 ? '1px solid #000' : '1px solid #C7C7C7',
            }"
            class="f-center pb10 bold"
            @click="changetype('0')"
          >
            Email
          </div>
          <div
            :style="{
              width: '50%',
              color: active == 1 ? '#000' : '#C7C7C7',
              'border-bottom':
                active == 1 ? '1px solid #000' : '1px solid #C7C7C7',
            }"
            class="f-center pb10 bold"
            @click="changetype('1')"
          >
            Phone
          </div>
        </div>
      </div>
      <div
        class="mt20"
        v-if="active == 0"
        style="overflow: hidden; width: 100%"
      >
        <input
          v-model="form.email"
          type="email"
          placeholder="Enter your email"
          class="input f13 p15"
        />
        <input
          v-model="form.password"
          type="password"
          placeholder="Please enter your password"
          class="input f13 p15 mt10"
        />
      </div>
      <!-- 2 -->
      <div class="mt20" v-else style="overflow: hidden; width: 100%">
        <div
          class="flex_start flex_items f13 plr15 mt10 input"
          style="overflow: hidden"
        >
          <template>
            <VueCountryIntl
              schema="popover"
              v-model="phoneCountry"
              :onlyValue="true"
              searchInputPlaceholder="Search"
              noDataText="No data"
              :useChinese="false"
            >
              <div
                class="flex_start flex_items pr10"
                style="border-right: 1px solid #c7c7c7"
                slot="reference"
              >
                <van-image
                  class="mr5"
                  width="8"
                  fit="contain"
                  :src="require('@/images/home/numsanjiao.png')"
                ></van-image>
                <span>+{{ phoneCountry }}</span>
              </div>
            </VueCountryIntl>
          </template>
          <input
            type="number"
            placeholder="Enter your phone number"
            class="input f13 pl10"
            style="height: 47px"
            v-model="form.phone"
          />
        </div>
        <input
          v-model="form.password"
          type="password"
          placeholder="Please enter your password"
          class="input f13 p15 mt10"
        />
      </div>
      <!-- 3 -->
      <div
        class="mt30 sub f-center"
        @click="loginsub"
        :style="{
          color: '#fff',
          background:
            (form.phone != null || form.email != null) && form.password != null
              ? '#FF7900'
              : '#ffd6b1',
        }"
      >
        Log In
      </div>
    </div>
  </div>
</template>  
 

<script>
import { Toast } from "vant";

export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      active: 0,
      form: {
        password: null,
        email: null,
        phone: null,
      },
      phoneCountry: 93,
    };
  },
  components: {},
  created() {
    console.log(this.$md5("holle"));
  },
  methods: {
    changetype(idx) {
      if (idx == this.active) {
        return false;
      }
      this.form = {
        password: null,
        email: null,
        phone: null,
      };
      this.active = idx;
    },
    loginsub() {
      if (
        (this.form.phone != null || this.form.email != null) &&
        this.form.password != null
      ) {
        Toast.loading({
          message: "Loading...",
          forbidClick: true,
        });
        let pass = this.$md5(this.form.password + this.form.password);
        let pc = null;
        if (this.form.email == null) {
          pc = this.phoneCountry + ":" + this.form.phone;
        }
        this.util
          .post(this.HOST + "/login", {
            appVersion: "1.0.6",
            country: "cn",
            deviceName: "iphone",
            email: this.form.email,
            mobile: pc,
            language: "en",
            osVersion: "14.7.1",
            password: pass.toUpperCase(),
            platform: 3,
            timestamp: "1630925015352",
            timezone: "Asia/Shanghai",
            smsCode: "",
          })
          .then((res) => {
            console.log(res);
            if (res.code == "000") {
              localStorage.setItem(
                "token",
                "bearer " + res.data.tokenInfo.access_token
              );
              localStorage.setItem("account", res.data.personalInfo.name);
              this.$router.push({
                name: "Home",
                params: {},
              });
            } else {
              Toast(res.msg);
            }
          });
      }
    },
  },
  beforeCreate() {
    window.document.body.style.backgroundColor = "#fff";
  },
  beforeDestroy() {
    window.document.body.style.backgroundColor = "";
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      to.meta.isgo = false;
    }
    next();
  },
};
</script>
<style>
.login .input {
  background: #f5f5f5;
  opacity: 1;
  border-radius: 4px;
  color: #000;
  border: none;
  width: 100%;
}
.login .sub {
  opacity: 1;
  border-radius: 4px;
  height: 44px;
  line-height: 44px;
}
.vue-country-intl-popover .search-input {
  width: 88% !important;
}
</style>