<template>
  <div v-if="istrue" class="following">
    <!-- list -->
    <div v-if="List.length != 0" class="flex_column bgwhite">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        pulling-text="Loading..."
        loosing-text="Loading..."
        loading-text="Loading..."
      >
        <div class="container-water-fall case">
          <waterfall
            :col="col"
            :gutterWidth="20"
            :data="List"
            @loadmore="loadmore"
            :lazyDistance="200"
          >
            <template>
              <div
                class="pb10 bbe9s"
                v-for="(item, index) in List"
                :key="index"
              >
                <div v-if="item.type == 'feeds'">
                  <div v-if="item.data.blog == 'null'">
                    <div class="flex_between flex_items p10">
                      <div class="flex_start flex_items">
                        <van-image
                          v-if="item.data.headerUrl != null"
                          round
                          width="40"
                          height="40"
                          fit="cover"
                          :src="item.data.headerUrl"
                          class="mr10"
                        ></van-image>
                        <van-image
                          v-else
                          round
                          width="40"
                          height="40"
                          fit="cover"
                          :src="require('@/images/home/mtx.png')"
                          class="mr10"
                        ></van-image>
                        <div class="flex_column">
                          <p class="c0 f15 bold">{{ item.data.nickName }}</p>
                          <p class="f12" style="color: #8e8e8e">15 min ago</p>
                        </div>
                      </div>
                      <div
                        v-if="!item.data.follow"
                        class="ptb5 plr10 f14"
                        style="
                          border-radius: 4px;
                          background: #ff7900;
                          color: white;
                        "
                        @click="follow(index, item.data.author)"
                      >
                        Follow
                      </div>
                    </div>
                    <imgstype :imgs="item.data.images"></imgstype>
                    <div
                      class="flex_between flex_items p10"
                      @click="workdetail(item.data.author, item.data.permlink)"
                    >
                      <div class="flex_start flex_items">
                        <van-image
                          v-if="item.data.like"
                          fit="contain"
                          :src="require('@/images/home/zan1.png')"
                          class="mr15"
                          width="24"
                        ></van-image>
                        <van-image
                          v-else
                          fit="contain"
                          :src="require('@/images/home/zan.png')"
                          class="mr15"
                          width="24"
                          @click.stop="
                            zan(index, item.data.author, item.data.permlink)
                          "
                        ></van-image>
                        <van-image
                          fit="contain"
                          :src="require('@/images/home/pinlun.png')"
                          class="mr15"
                          width="24"
                          @click="app"
                        ></van-image>
                        <div
                          @click.stop="zhuan(index)"
                          class="mr15"
                          style="height: 24px"
                        >
                          <zhuanshow
                            :isshow="item.data.zhuanshow"
                            :author="item.data.author"
                            :permlink="item.data.permlink"
                          ></zhuanshow>
                        </div>
                        <div
                          style="height: 24px"
                          @click.stop="moreshowclisk(index, item)"
                        >
                          <van-image
                            fit="contain"
                            :src="require('@/images/home/more.png')"
                            class="mr15"
                            width="24"
                          ></van-image>
                        </div>
                      </div>
                      <div class="rpix bold f12 plr10">
                        {{ item.data.totalMoney }}&nbsp;PIX
                      </div>
                    </div>
                    <!-- likes -->
                    <div class="flex_start flex_items plr10">
                      <span class="mr5 f13"
                        ><span class="bold">{{ item.data.likeNum }}&nbsp;</span
                        ><span v-if="item.data.likeNum < 2">Like</span>
                        <span v-else>Likes</span>
                      </span>
                      <span class="mr5 f13 bold">·</span>
                      <span class="mr5 f13"
                        ><span class="bold"
                          >{{ item.data.commentNum }}&nbsp;</span
                        ><span v-if="item.data.commentNum < 2">Comment</span
                        ><span v-else>Comments</span></span
                      >
                    </div>
                    <p
                      v-if="item.data.description != null"
                      class="f14 c0 mt10 plr10"
                    >
                      {{ item.data.description }}
                    </p>
                    <div
                      v-if="item.data.primaryTag != null"
                      class="flex_warp flex_items f14 mt5"
                      style="color: #0a82fa"
                    >
                      <span class="ml10 mt5">#{{ item.data.primaryTag }}</span>
                      <span
                        class="ml10 mt5"
                        v-for="item in item.data.tags"
                        :key="item.id"
                        >#{{ item }}</span
                      >
                    </div>
                    <!-- pinglun -->
                    <div class="plr10 flex_column f14 mt5">
                      <div v-if="item.data.repliesNum != 0">
                        <p
                          v-for="(item2, index2) in item.data.replies"
                          :key="index2"
                          style="color: #666666"
                        >
                          <span class="mr5 bold c0"> {{ item2.nickName }} </span
                          >{{ item2.body }}
                        </p>
                      </div>

                      <p
                        v-if="item.data.repliesNum != 0"
                        class="mt10 f12"
                        style="color: #8e8e8e"
                      >
                        View all {{ item.data.repliesNum }}
                        <span v-if="item.data.repliesNum < 2">comment</span
                        ><span v-else>comments</span>
                      </p>
                      <div
                        v-if="
                          item.data.hotState == 1 || item.data.nftStatus == 1
                        "
                        class="mt10 flex_start flex_items"
                      >
                        <div
                          v-if="item.data.hotState == 1"
                          class="fire plr5 flex_start flex_items mr10"
                        >
                          <van-image
                            fit="contain"
                            :src="require('@/images/home/fire.png')"
                            class="mr5"
                            width="14"
                          ></van-image>
                          <span class="f12 c0">Trending</span>
                        </div>
                        <div
                          v-if="item.data.nftStatus == 2"
                          class="fire plr5 flex_center flex_items mr10"
                        >
                          <van-image
                            fit="contain"
                            :src="require('@/images/home/nft2.png')"
                            class="mr5"
                            width="20"
                          ></van-image>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="p10 flex_column mt10" @click="workdetail">
                    <div class="flex_start flex_items">
                      <van-image
                        round
                        width="40"
                        height="40"
                        fit="cover"
                        :src="item.data.blog_headerUrl"
                        class="mr10"
                      ></van-image>
                      <div class="flex_column">
                        <p class="c0 f15 bold">
                          <span v-if="item.data.blogFollowingRemark != null">{{
                            item.data.blogFollowingRemark
                          }}</span>
                          <span v-else>{{ item.data.blog_nickname }}</span>
                        </p>
                        <p class="f12" style="color: #8e8e8e">15 min ago</p>
                      </div>
                    </div>
                    <div
                      class="p10 flex_column mt10"
                      style="border: 1px solid #e8e8e8"
                    >
                      <div class="flex_start flex_items">
                        <van-image
                          round
                          width="40"
                          height="40"
                          fit="cover"
                          :src="item.data.blog_headerUrl"
                          class="mr10"
                        ></van-image>
                        <div class="flex_column">
                          <p class="c0 f15 bold">
                            <span v-if="item.data.followingRemark != null">{{
                              item.data.followingRemark
                            }}</span>
                            <span v-else>{{ item.data.nickName }}</span>
                          </p>
                          <p class="f12" style="color: #8e8e8e">15 min ago</p>
                        </div>
                      </div>
                      <div class="flex_column mt10">
                        <imgstype :imgs="item.data.images"></imgstype>
                        <div class="flex_between flex_items p10">
                          <div class="flex_start flex_items">
                            <van-image
                              v-if="item.data.like"
                              fit="contain"
                              :src="require('@/images/home/zan1.png')"
                              class="mr15"
                              width="24"
                            ></van-image>
                            <van-image
                              v-else
                              fit="contain"
                              :src="require('@/images/home/zan.png')"
                              class="mr15"
                              width="24"
                            ></van-image>
                            <van-image
                              fit="contain"
                              :src="require('@/images/home/pinlun.png')"
                              class="mr15"
                              width="24"
                            ></van-image>
                            <div class="mr15" style="height: 24px"></div>
                            <div style="height: 24px">
                              <van-image
                                fit="contain"
                                :src="require('@/images/home/more.png')"
                                class="mr15"
                                width="24"
                              ></van-image>
                            </div>
                          </div>
                          <div class="rpix bold f12 plr10">
                            {{ item.data.totalMoney }} PIX
                          </div>
                        </div>
                        <!-- likes -->
                        <div class="flex_start flex_items plr10">
                          <span class="mr5 f13"
                            ><span class="bold"
                              >{{ item.data.likeNum }}&nbsp;</span
                            ><span v-if="item.data.likeNum < 2">Like</span>
                            <span v-else>Likes</span>
                          </span>
                          <span class="mr5 f13 bold">·</span>
                          <span class="mr5 f13"
                            ><span class="bold"
                              >{{ item.data.commentNum }}&nbsp;</span
                            ><span v-if="item.data.commentNum < 2">Comment</span
                            ><span v-else>Comments</span></span
                          >
                        </div>
                        <p
                          v-if="item.data.description != null"
                          class="f14 c0 mt10 plr10"
                        >
                          {{ item.data.description }}
                        </p>
                        <div
                          v-if="item.data.primaryTag != null"
                          class="flex_warp flex_items f14 mt5"
                          style="color: #0a82fa"
                        >
                          <span class="ml10 mt5"
                            >#{{ item.data.primaryTag }}</span
                          >
                          <span
                            class="ml10 mt5"
                            v-for="item in item.data.tags"
                            :key="item.id"
                            >#{{ item }}</span
                          >
                        </div>
                        <!-- pinglun -->
                        <div class="plr10 flex_column f14 mt5">
                          <div v-if="item.repliesNum != 0">
                            <p
                              v-for="(item2, index2) in item.data.replies"
                              :key="index2"
                              style="color: #666666"
                            >
                              <span class="mr5 bold c0">
                                {{ item2.nickName }} </span
                              >{{ item2.body }}
                            </p>
                          </div>

                          <p
                            v-if="item.data.repliesNum != 0"
                            class="mt10 f12"
                            style="color: #8e8e8e"
                          >
                            View all {{ item.data.repliesNum }}
                            <span v-if="item.data.repliesNum < 2">comment</span
                            ><span v-else>comments</span>
                          </p>
                          <div
                            v-if="
                              item.data.hotState == 1 ||
                              item.data.nftStatus == 1
                            "
                            class="mt10 flex_start flex_items"
                          >
                            <div
                              v-if="item.data.hotState == 1"
                              class="fire plr5 flex_start flex_items mr10"
                            >
                              <van-image
                                fit="contain"
                                :src="require('@/images/home/fire.png')"
                                class="mr5"
                                width="14"
                              ></van-image>
                              <span class="f12 c0">Trending</span>
                            </div>
                            <div
                              v-if="item.data.nftStatus == 2"
                              class="fire plr5 flex_center flex_items mr10"
                            >
                              <van-image
                                fit="contain"
                                :src="require('@/images/home/nft2.png')"
                                class="mr5"
                                width="20"
                              ></van-image>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="f13 mt5">
                      Repost from
                      <span style="color: #0a82fa"
                        >@
                        <span v-if="item.data.followingRemark != null">{{
                          item.data.followingRemark
                        }}</span>
                        <span v-else>{{ item.data.nickName }}</span>
                      </span>
                    </p>
                  </div>
                </div>
                <!-- 2 -->
                <div v-else style="height: 0"></div>
              </div>
            </template>
          </waterfall>
        </div>
      </van-pull-refresh>
    </div>
    <div v-else class="flex_items flex_center" :style="{ height: ch }">
      <div class="flex_column flex_items">
        <van-image
          class=""
          width="131"
          height="132"
          fit="contain"
          :src="require('@/images/home/empty.png')"
        ></van-image>
        <p class="mt5 f15 f-center" style="color: #8e8e8e">Empty！</p>
      </div>
    </div>
  </div>
</template>
<script>
import imgstype from "@/components/imgstype.vue";
import zhuanshow from "@/components/zhuanshow.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      show: true,
      istrue: false,
      List: [],
      col: 1,
      isLoading: false,
      morepopshow: false,
      ismoreshow: false,
      isreport: false,
      ismoreindex: 0,
      ismoreauthor: "",
      ismorepermlink: "",
      iscollection: "Save to Collection",
      resons: [
        { id: 0, title: "Spam" },
        { id: 1, title: "Pornographic" },
        { id: 2, title: "Copyright Infringement" },
        { id: 3, title: "Other Reasons" },
      ],
      ch: window.screen.height - 104 - 48 + "px",
    };
  },
  props: ["isresfollow", "ischange"],
  components: {
    imgstype,
    zhuanshow,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (!this.isLoading) {
        Toast.loading({
          message: "Loading...",
          forbidClick: true,
        });
      }
      this.util
        .get(this.HOST + "/posts/follows-by-createTime", {
          appVersion: "1.0.6",
          country: "cn",
          createTime: "0",
          deviceName: "iphone",
          language: "en",
          osVersion: "14.7.1",
          pageSize: 12,
          platform: 2,
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
          version: 5,
          isRefresh: this.isRefresh,
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            if (localStorage.getItem("token") == null) {
              Toast("Please log in!");
              this.$router.replace({ name: "login" });
              return false;
            }
            if (res.data.list == null) {
              return false;
            }
            let arr = [];
            res.data.list.forEach((item) => {
              if (item.type == "feeds") {
                arr.push(item);
              }
            });
            if (this.isRefresh == 1) {
              this.List = arr;
            } else {
              arr.forEach((item) => {
                this.List.push(item);
              });
            }
            this.List.forEach((item) => {
              item.data.zhuanshow = false;
              item.data.blog = "null";
            });
            console.log(arr);
          } else {
            Toast(res.msg);
          }
          this.isLoading = false;
          this.istrue = true;
        });
    },
    loadmore() {
      this.isRefresh = 0;
      this.getData();
    },
    onRefresh() {
      this.isRefresh = 1;
      this.getData();
    },
    zan(index, author, permlink) {
      this.util
        .zan(this.HOST + "/likes", {
          author: author,
          permlink: permlink,
          appVersion: "1.0.5",
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          platform: "2",
          timestamp: "1631097660820",
          timezone: "Asia/Shanghai",
          version: "2",
          voter: "p37224842",
        })
        .then((res) => {
          this.List[index].data.like = true;
          this.List[index].data.likeNum += 1;
        });
    },
    zhuan(index) {
      this.List[index].data.zhuanshow = !this.List[index].data.zhuanshow;
    },
    follow(index, author) {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      this.util
        .follow(this.HOST + "/follow", {
          following: author,
          appVersion: "1.0.5",
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          osVersion: "14.7.1",
          platform: "2",
          timestamp: "1631097660820",
          timezone: "Asia/Shanghai",
        })
        .then((res) => {
          if (res.code == "000") {
            Toast("Following");
            this.List[index].data.follow = true;
          } else {
            Toast(res.msg);
          }
          this.ismoreshow = false;
        });
    },
    moreshowclisk(index, item) {
      let floow = "";
      if (this.List[index].data.follow) {
        floow = "Unfollow";
      } else {
        floow = "Follow";
      }
      if (this.List[index].data.collection) {
        this.iscollection = "Remove from Collection";
      } else {
        this.iscollection = "Save to Collection";
      }
      this.ismoreshow = true;
      this.ismoreindex = index;
      let data = {
        isfollw: floow,
        iscollection: this.iscollection,
        ismoreshow: this.ismoreshow,
        ismoreindex: this.ismoreindex,
        ismoreauthor: item.data.author,
        ismorepermlink: item.data.permlink,
        body: item.data.body,
      };
      this.$emit("getmoreshow", data);
    },
    workdetail(author, permlink) {
      this.$router.push({
        name: "workdetails",
        params: {
          author: author,
          permlink: permlink,
        },
      });
    },
    getgo(val) {
      this.List = val;
    },
    app() {
      this.util.app();
    },
  },
  watch: {
    ischange(val, newval) {
      if (val == 1) {
        this.List[this.ismoreindex].data.follow = this.isresfollow;
      }
    },
  },
};
</script>
<style>
.following .rpix {
  background: linear-gradient(85deg, #ffead6 0%, #ffe0c2 100%);
  opacity: 1;
  border-radius: 4px;
  color: #ff5500;
  height: 20px;
  line-height: 20px;
}
.following .fire {
  background: #f5f5f5;
  border-radius: 6px 0px 6px 0px;
  height: 26px;
  line-height: 26px;
}
</style>