import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import Axios from 'axios'
import qs from "qs";
import util from "@/util/util.js";
import Vant from 'vant';
import "@/assets/style.css";
import 'vant/lib/index.css';
import waterfall from 'vue-waterfall2'
import VideoPlayer from 'vue-video-player'
import VueCountryIntl from 'vue-country-intl';
import 'vue-country-intl/lib/vue-country-intl.css'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import md5 from 'js-md5';
import VueSocialSharing from 'vue-social-sharing'
import InnerObject from "./config/env.js"

Vue.use(VueSocialSharing);
Vue.use(VideoPlayer)
Vue.use(Vant);
Vue.use(waterfall)
Vue.component(VueCountryIntl.name, VueCountryIntl);

Vue.config.productionTip = false

Vue.prototype.$http = Axios
Vue.prototype.$qs = qs;
Vue.prototype.util = util
Vue.prototype.$md5 = md5;
Vue.prototype.HOST = process.env.NODE_ENV === "production" ? InnerObject.url : "/api"
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')