/* eslint-disable */
// eslint-disable-next-line
import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue";
import Home from "../views/Home/Home.vue";
import { Toast } from "vant";
// 解决重复路由
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "login",
        meta: {
            title: 'Login',
            tag: true
        },
        component: login,
    },
    {
        path: "/Home",
        name: "Home",
        meta: {
            title: 'Pixie',
            keepAlive: true,
            isgo: false
        },
        component: Home,
    },
    {
        path: "/secondflow",
        name: "secondflow",
        meta: {
            keepAlive: true,
            isback: true,
            title: 'Pixie',
        },
        component: () =>
            import ("../views/secondflow/secondflow.vue"),
    },
    {
        path: "/workdetails",
        name: "workdetails",
        meta: {
            keepAlive: false,
            title: 'Pixie',
        },
        component: () =>
            import ("../views/workdetails/workdetails.vue"),
    },
    {
        path: "/post/:author/:permlink",
        name: "workdetail",
        meta: {
            keepAlive: false,
            title: 'Pixie',
            tag: true
        },
        component: () =>
            import ("../views/workdetails/workdetails.vue"),
    },
    {
        path: "/allreply",
        name: "allreply",
        meta: {
            keepAlive: false,
            title: 'Pixie',
        },
        component: () =>
            import ("../views/allreply/allreply.vue"),
    },
    {
        path: "/person",
        name: "person",
        meta: {
            keepAlive: false,
            title: 'Pixie',
        },
        component: () =>
            import ("../views/person/person.vue"),
    },
    {
        path: "/selfwork",
        name: "selfwork",
        meta: {
            keepAlive: true,
            title: 'Pixie',
        },
        component: () =>
            import ("../views/selfwork/selfwork.vue"),
    }, {
        path: "/wallet",
        name: "wallet",
        meta: {
            keepAlive: false,
            title: 'PIX Wallet'
        },
        component: () =>
            import ("../views/wallet/wallet.vue"),
    }, {
        path: "/mynft",
        name: "mynft",
        meta: {
            keepAlive: false,
            tag: true,
            title: 'NFT',
        },
        component: () =>
            import ("../views/mynft/mynft.vue"),
    }, {
        path: "/qrcode",
        name: "qrcode",
        meta: {
            keepAlive: false,
            tag: true,
            title: 'Pixie',
        },
        component: () =>
            import ("../views/qrcode/qrcode.vue"),
    }, {
        path: "/search",
        name: "search",
        meta: {
            keepAlive: false,
            tag: true,
            title: 'Pixie',
        },
        component: () =>
            import ("../views/search/search.vue"),
    }, {
        path: "/deposit",
        name: "deposit",
        meta: {
            keepAlive: false,
            tag: true,
            title: 'Pixie',
        },
        component: () =>
            import ("../views/deposit/deposit.vue"),
    }, {
        path: "/profile",
        name: "profile",
        meta: {
            keepAlive: false,
            tag: true,
            title: 'Profile',
        },
        component: () =>
            import ("../views/profile/profile.vue"),
    },
];
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});
//全局
router.beforeEach((to, from, next) => {
    let token = localStorage.getItem("token")
    if (token) {
        if (to.name == 'login') {
            next({ path: '/Home' })
        } else {
            next()
        }
    }
    next()
        // else {
        //     if (to.name !== 'login') {
        //         Toast("Please log in!");
        //         next({ path: '/' })
        //     } else {
        //         next()
        //     }
        // }
    if (to.meta.title) {
        document.title = to.meta.title
    }
})
export default router;