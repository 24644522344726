<template>
  <div class="trending">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      pulling-text="Loading..."
      loosing-text="Loading..."
      loading-text="Loading..."
    >
      <div class="container-water-fall case">
        　　　　
        <waterfall
          :col="col"
          :gutterWidth="20"
          :data="List"
          @loadmore="loadmore"
          :lazyDistance="200"
        >
          <template>
            <div
              class="case_item cell-item bgwhite mt5"
              v-show="List.length !== 0"
              v-for="(item, index) in List"
              :key="index"
              style="border-radius: 4px; overflow: hidden"
              @click="secondflow(item, index)"
            >
              <div class="imgs pos" style="width: 100%">
                <img
                  v-if="item.images[0].videoUrl == null"
                  lazy-load
                  :src="item.images[0].photoThumbnailUrl"
                  class="image"
                  style="width: 100%"
                />
                <img
                  v-if="item.images[0].videoUrl != null"
                  lazy-load
                  :src="item.images[0].gifUrl"
                  class="image"
                  style="width: 100%"
                />
                <img
                  v-if="item.images[0].videoUrl != null"
                  class="abs"
                  :src="require('@/images/trending/play.png')"
                  style="right: 3px; top: 3px; width: 24px"
                />
                <div
                  v-if="item.nftStatus == 2"
                  class="abs isnft flex_items flex_center"
                >
                  <van-image
                    class=""
                    width="16"
                    fit="contain"
                    :src="require('@/images/home/nft.png')"
                  />
                </div>
                <van-image
                  v-if="
                    item.images.length > 1 && item.images[0].videoUrl == null
                  "
                  class=""
                  width="24"
                  fit="contain"
                  style="right: 3px; top: 3px; width: 24px; position: absolute"
                  :src="require('@/images/home/imgs.png')"
                />
              </div>
              <div class="case_bottom plr10 ptb5">
                <div class="flex_start flex_items">
                  <van-image
                    v-if="item.headerUrl != null"
                    class="mr5"
                    width="28"
                    fit="contain"
                    :src="item.headerUrl"
                    round
                  ></van-image>
                  <van-image
                    v-else
                    class="mr5"
                    width="28"
                    fit="contain"
                    :src="require('@/images/home/mtx.png')"
                    round
                  ></van-image>
                  <span class="c0 f14">{{ item.nickName }}</span>
                </div>
                <p class="f13 mt5 description" v-if="item.description != ''">
                  {{ item.description }}
                </p>
                <div class="flex_between flex_items mt10 pb10">
                  <div class="flex_start flex_items">
                    <van-image
                      class="mr5"
                      width="14"
                      fit="contain"
                      :src="require('@/images/home/zan.png')"
                    ></van-image>
                    <span class="f12 c0">{{ item.likeNum }}</span>
                  </div>
                  <span
                    style="
                      color: #ff5500;
                      background: #ffe0c2;
                      border-radius: 4px;
                      height: 20px;
                      line-height: 20px;
                    "
                    class="f12 plr10"
                    >{{ item.totalMoney }} PIX</span
                  >
                </div>
              </div>
            </div>
          </template>
        </waterfall>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      col: 2,
      page: 1,
      isLoading: false,
      isRefresh: 1,
      List: [],
      isgo: false,
    };
  },
  created() {
    this.isgo = true;
    this.getData();
  },
  methods: {
    getData() {
      if (!this.isgo) {
        return false;
      }
      if (!this.isLoading) {
        Toast.loading({
          message: "Loading...",
          forbidClick: true,
        });
      }
      this.util
        .get(this.HOST + "/posts/hot", {
          appVersion: "1.0.3",
          country: "cn",
          currentPage: this.page,
          deviceName: "iphone",
          isRefresh: this.isRefresh,
          language: "en",
          osVersion: "14.6",
          pageNum: 2,
          platform: 2,
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
          version: 2,
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            if (this.isRefresh == 1) {
              this.List = res.data.list;
            } else {
              res.data.list.forEach((item) => {
                this.List.push(item);
              });
            }
            this.isLoading = false;
            this.isgo = false;
          } else {
            Toast(res.msg);
          }
        });
    },
    loadmore() {
      this.page += 1;
      this.isRefresh = 0;
      this.isgo = true;
      this.getData();
    },
    onRefresh() {
      this.page = 1;
      this.isRefresh = 1;
      this.isgo = true;
      this.getData();
    },
    secondflow(item, index) {
      this.$router.push({
        name: "secondflow",
        params: {
          title: "Trending",
          zhis: {
            appVersion: "1.0.3",
            country: "cn",
            currentPage: 1,
            deviceName: "iphone",
            isRefresh: 1,
            language: "en",
            osVersion: "14.6",
            pageNum: 2,
            platform: 2,
            timestamp: "1630925015352",
            timezone: "Asia/Shanghai",
            version: 2,
          },
          url: this.HOST + "/posts/hot",
          list: this.List,
          index: index,
        },
      });
    },
  },
};
</script>
<style>
.trending .vue-waterfall-column {
  width: 47.6vw !important;
  padding: 1px;
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>