<template>
  <div id="app">
    <!-- <router-view /> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <div
      v-if="!$route.meta.tag"
      style="border-top: 1px solid #e8e8e8"
      class="tabar ptb10 bgwhite flex_around flex_items"
    >
      <van-image
        class=""
        width="28"
        fit="contain"
        :src="active == 0 ? icon.active1 : icon.inactive1"
        @click="changetab('0')"
      ></van-image>
      <van-image
        class=""
        width="28"
        fit="contain"
        :src="active == 1 ? icon.active2 : icon.inactive2"
        @click="changetab('1')"
      ></van-image>
      <van-image
        class=""
        width="28"
        fit="contain"
        :src="active == 2 ? icon.active3 : icon.inactive3"
        @click="changetab('2')"
      ></van-image>
    </div>
    <!-- share -->
    <van-overlay :show="shareshow" @click="shareshow = false" />
    <transition>
      <div class="flex_column flex_items plr10 ptb20 sharek" v-if="shareshow">
        <div class="flex_around flex_items" style="width: 100%">
          <div class="flex_column flex_items">
            <van-image
              class=""
              width="60"
              fit="contain"
              :src="require('@/images/home/photo.png')"
            ></van-image>
            <p class="mt10 f13" style="color: #fff">Photo</p>
          </div>
          <div class="flex_column flex_items">
            <van-image
              class=""
              width="60"
              fit="contain"
              :src="require('@/images/home/video.png')"
            ></van-image>
            <p class="mt10 f13" style="color: #fff">Video</p>
          </div>
          <div class="flex_column flex_items">
            <van-image
              class=""
              width="60"
              fit="contain"
              :src="require('@/images/home/xianft.png')"
            ></van-image>
            <p class="mt10 f13" style="color: #fff">NFT</p>
          </div>
        </div>
        <div class="flex_items flex_center mt20" style="width: 100%">
          <van-image
            class=""
            width="44"
            fit="contain"
            :src="require('@/images/home/guan.png')"
            @click="shareshow = false"
          ></van-image>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      active: 0,
      icon: {
        active1: require("@/images/home/home1.png"),
        inactive1: require("@/images/home/home.png"),
        active2: require("@/images/home/fabu.png"),
        inactive2: require("@/images/home/fabu.png"),
        active3: require("@/images/home/person1.png"),
        inactive3: require("@/images/home/person.png"),
      },
      shareshow: false,
      options: [
        {
          name: "Photo",
          icon: require("@/images/home/photo.png"),
        },
        {
          name: "Video",
          icon: require("@/images/home/video.png"),
        },
        {
          name: "NFT",
          icon: require("@/images/home/xianft.png"),
        },
      ],
    };
  },
  created() {
    // this.changetab(this.active, "start");
  },
  methods: {
    changetab(idx, aa) {
      if (idx == this.active && !aa) {
        return false;
      }
      if (idx == 1) {
        this.shareshow = true;
        return false;
      }
      this.active = idx;
      if (this.active == 0) {
        this.$router.push({ path: "/Home", replace: true });
      }
      if (this.active == 2) {
        this.$router.push({ path: "/person", replace: true });
      }
    },
  },
  components: {},
};
</script>

<style>
.tabar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}
.sharek {
  position: fixed;
  left: 0;
  width: 95%;
  z-index: 12;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px) brightness(50%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
