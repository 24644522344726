<template>
  <div class="home">
    <homeheader></homeheader>
    <!-- t2 -->
    <div class="">
      <van-tabs
        v-model="active"
        sticky
        swipeable
        title-active-color="#000000"
        title-inactive-color="#8E8E8E"
        color="#FF7900"
        animated
        @click="tabchange"
      >
        <van-tab title="Following" class="p5 bgwhite"
          ><following
            @getmoreshow="getmoreshow2"
            :isresfollow="isresfollow"
            :ischange="ischange"
          ></following
        ></van-tab>
        <van-tab title="Trending" class="p5"><trending></trending></van-tab>
        <van-tab title="Explore" class="p5"><explore></explore></van-tab>
        <van-tab>
          <div slot="title">
            <van-image
              class="mr5"
              width="12"
              fit="contain"
              :src="require('@/images/home/hot.png')"
            ></van-image
            >NFT
          </div>
          <nft></nft>
        </van-tab>
      </van-tabs>
    </div>
    <!-- more -->
    <van-popup
      v-model="ismoreshow"
      class="p10"
      style="border-radius: 4px; width: 60vw"
    >
      <div
        style="width: 100%"
        class="ptb10 f14 c0"
        @click="follow(ismoreauthor)"
      >
        {{ isfollw }}
      </div>
      <div
        style="width: 100%"
        class="ptb10 f14 c0"
        @click="collection(ismoreindex, ismoreauthor, ismorepermlink)"
      >
        {{ iscollection }}
      </div>
      <div style="width: 100%" class="ptb10 f14 c0" @click="report">
        Report this Post
      </div>
    </van-popup>
    <!-- isreport -->
    <van-popup
      v-model="isreport"
      class="p10"
      style="border-radius: 4px; width: 60vw"
    >
      <div
        style="width: 100%"
        class="ptb10 f14 c0"
        v-for="item in resons"
        :key="item.id"
        @click="reportsub(item.id)"
      >
        {{ item.title }}
      </div>
    </van-popup>
  </div>
</template>
<script>
import following from "@/components/following.vue";
import trending from "@/components/trending.vue";
import explore from "@/components/explore.vue";
import nft from "@/components/nft.vue";
import homeheader from "@/components/homeheader.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      active: 1,
      istrue: false,
      ismoreshow: false,
      isreport: false,
      ismoreindex: 0,
      ismoreauthor: "",
      ismorepermlink: "",
      isfollw: "Follow",
      isresfollow: false,
      ischange: 0,
      body: "",
      iscollection: "Save to Collection",
      resons: [
        { id: 0, title: "Spam" },
        { id: 1, title: "Pornographic" },
        { id: 2, title: "Copyright Infringement" },
        { id: 3, title: "Other Reasons" },
      ],
    };
  },
  components: {
    trending,
    explore,
    nft,
    following,
    homeheader,
  },
  // created() {
  //   this.istrue = true
  // },
  activated() {
    if (!this.$route.meta.isgo) {
      this.istrue = true;
    }
  },
  methods: {
    tabchange(val) {
      console.log(val);
    },
    onClickDisabled() {
      // console.log(123);
    },
    getmoreshow2(val) {
      this.isfollw = val.isfollw;
      this.iscollection = val.iscollection;
      this.ismoreshow = val.ismoreshow;
      this.ismoreindex = val.ismoreindex;
      this.ismoreauthor = val.ismoreauthor;
      this.ismorepermlink = val.ismorepermlink;
      this.ischange = 0;
      this.body = val.body;
    },
    follow(author) {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      if (this.isfollw == "Unfollow") {
        this.unfollow(author);
        return false;
      }
      this.util
        .follow(this.HOST + "/follow", {
          following: author,
          appVersion: "1.0.5",
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          osVersion: "14.7.1",
          platform: "2",
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
        })
        .then((res) => {
          if (res.code == "000") {
            Toast("Following");
            // this.List[index].data.follow = true;
            this.isresfollow = true;
            this.ischange = 1;
          } else {
            Toast(res.msg);
            this.ischange = 2;
          }
          this.ismoreshow = false;
        });
    },
    unfollow(author) {
      this.util
        .follow(this.HOST + "/unfollow", {
          following: author,
          appVersion: "1.0.5",
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          osVersion: "14.7.1",
          platform: "2",
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
        })
        .then((res) => {
          if (res.code == "000") {
            Toast("Unfollowed");
            this.isresfollow = false;
            this.ischange = 1;
          } else {
            Toast(res.msg);
            this.ischange = 2;
          }
          this.ismoreshow = false;
        });
    },
    report() {
      this.ismoreshow = false;
      this.isreport = true;
    },
    reportsub(id) {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      this.util
        .report(this.HOST + "/reporting", {
          permlink: this.ismorepermlink,
          author: this.ismoreauthor,
          type: id,
          account: localStorage.getItem("token"),
          imageKey: this.body,
        })
        .then((res) => {
          if (res.code == "000") {
            Toast("Thank you for your feedback");
          } else {
            Toast(res.msg);
          }
          this.ismoreshow = false;
          this.isreport = false;
        });
    },
  },
  beforeCreate(to, from, next) {
    window.document.body.style.backgroundColor = "#f5f5f5";
  },
  beforeDestroy(to, from, next) {
    window.document.body.style.backgroundColor = "";
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "secondflow") {
      to.meta.isback = false;
    }
    next();
  },
};
</script>
<style>
.home .van-tab--active {
  font-weight: bold !important;
}
.home .van-tabs__wrap {
  border-bottom: 1px solid #dbdbdb;
}
.home {
  /* position: fixed; */
  height: 100%;
  width: 100%;
  background-color: #f5f5f5 !important;
}
</style>